import { AuthTokenRepository } from '@/repositories/authTokenRepository';

export class Auth {
    readonly clientId = 'QP5Etuvfdb3vEpP3Pz';

    constructor(private onAccessTokenReady: () => void) {
        window.addEventListener('hashchange', this.parseAccessTokenUrlHash, false);
        this.parseAccessTokenUrlHash();

        this.init();
    }

    private init() {
        if (AuthTokenRepository.isValid()) {
            this.signalAccessTokenReady();
            return;
        }

        AuthTokenRepository.clear();
        window.location.replace(
            `https://bitbucket.org/site/oauth2/authorize?client_id=${this.clientId}&response_type=token`
        );
    }

    private signalAccessTokenReady() {
        if (this.onAccessTokenReady) {
            this.onAccessTokenReady();
        }
    }

    private parseAccessTokenUrlHash() {
        if (location.hash.length > 1) {
            const params = new URLSearchParams(location.hash.substr(1));
            const token = params.get('access_token');
            const expiresIn = params.get('expires_in');

            if (token && expiresIn) {
                const expirationDate = new Date();
                expirationDate.setSeconds(expirationDate.getSeconds() + parseInt(expiresIn));

                AuthTokenRepository.setAuthToken(token, expirationDate);

                location.hash = '';
                this.signalAccessTokenReady();
            }
        }
    }
}
