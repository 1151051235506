







import { html } from 'gridjs';
import Grid from 'gridjs-vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Repository } from '../models/Repository';

@Component({
    components: {
        Grid,
    },
})
export default class RepositoriesTable extends Vue {
    @Prop() public repositories!: Repository[];

    get processedRepositories() {
        return this.repositories.sort((first, second) => (first.Slug < second.Slug ? -1 : 1));
    }

    public cols = [
        {
            id: 'Name',
            name: 'Name',
            sort: true,
        },
        {
            id: 'Slug',
            name: 'Slug',
            sort: true,
        },
        {
            id: 'Link',
            name: 'Link 🔗',
            sort: true,
            formatter: (cell: string) => {
                return html(`<a href="${cell}">${cell}</a>`);
            },
        },
    ];
}
