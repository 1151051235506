import { Repository } from '@/models/Repository';
import { getHttpClient } from '@/providers/httpClient';

export async function getAllRepositories(): Promise<Repository[]> {
    const client = getHttpClient();
    const repositories: Repository[] = [];
    let nextChunkUrl = 'https://bitbucket.org/api/2.0/repositories?role=member';

    do {
        const { data, status } = await client.get(nextChunkUrl);
        if (status == 200) {
            nextChunkUrl = data.next;
            data.values.forEach((value: any) => {
                const repository: Repository = {
                    Name: value.name,
                    Slug: value.slug,
                    PullRequestsApiLink: value.links.pullrequests.href,
                    Link: value.links.html.href,
                };
                repositories.push(repository);
            });
        } else {
            throw 'Error during fetching repositories';
        }
    } while (nextChunkUrl);

    return repositories;
}
