export class AuthTokenRepository {
    static readonly tokenKey = 'bitbucket-token';
    static readonly expirationDateKey = 'bitbucket-token-expiration';

    static clear() {
        window.localStorage.clear();
    }

    static getAuthToken(): string | null {
        return window.localStorage.getItem(this.tokenKey);
    }

    static isValid(): boolean {
        const token = window.localStorage.getItem(this.tokenKey);
        const expirationDateStr = window.localStorage.getItem(this.expirationDateKey);
        if (!token || !expirationDateStr) {
            return false;
        }

        return new Date(JSON.parse(expirationDateStr)) >= new Date();
    }

    static setAuthToken(authToken: string, validUntil: Date) {
        window.localStorage.setItem(this.tokenKey, authToken);
        window.localStorage.setItem(this.expirationDateKey, JSON.stringify(validUntil));
    }
}
