












import { PullRequest } from '@/models/PullRequest';
import Grid from 'gridjs-vue';
import { html } from 'gridjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        Grid,
    },
})
export default class PullRequestsTable extends Vue {
    @Prop() public pullRequests!: PullRequest[];

    get filteredPullRequests() {
        const lastWeekDate = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);

        return this.pullRequests
            .filter(pr => !this.hideMonolith || !pr.RepositoryName.includes('eloomi5.32'))
            .filter(pr => !this.hideMaster || pr.TargetBranch != 'master')
            .filter(pr => !this.onlyLastWeek || pr.CreatedOn > lastWeekDate)
            .sort((first, second) => (first.CreatedOn < second.CreatedOn ? -1 : 1));
    }

    public hideMonolith = true;
    public onlyLastWeek = true;
    public hideMaster = true;

    public cols = [
        {
            id: 'Id', //0
            name: 'PR id',
            hidden: true,
        },
        {
            id: 'RepositoryName', //1
            name: 'Repository 🔗',
            sort: true,
            formatter: (cell: string, row: any) => {
                return html(
                    `<a class="repo-cell" href="${row.cells[9].data}" target="_blank"> <img src="${row.cells[10].data}" /> <div>${cell}</div></a>`
                );
            },
        },
        {
            id: 'Title', //2
            name: 'Title 🔗',
            sort: true,
            formatter: (cell: string, row: any) => {
                return html(`<a href="${row.cells[8].data}" target="_blank">${cell}</a>`);
            },
        },
        {
            id: 'Author', //3
            name: 'Author',
            sort: true,
        },
        {
            id: 'CommentsNumber', //4
            name: 'Comments',
            sort: true,
        },
        {
            id: 'CreatedOn', //5
            name: 'Created',
            sort: true,
            formatter: (cell: Date) => {
                return cell.toLocaleString();
            },
        },
        {
            id: 'UpdatedOn', //6
            name: 'Updated',
            sort: true,
            formatter: (cell: Date) => {
                return cell?.toLocaleString();
            },
        },
        {
            id: 'State', //7
            name: 'State',
            hidden: true,
        },
        {
            id: 'Url', //8
            name: 'Url',
            hidden: true,
        },
        {
            id: 'RepositoryUrl', //9
            name: 'RepositoryUrl',
            hidden: true,
        },
        {
            id: 'RepositoryAvatar', //10
            name: 'RepositoryAvatar',
            hidden: true,
        },
    ];
}
