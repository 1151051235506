import axios, { AxiosInstance } from 'axios';
import { AuthTokenRepository } from '@/repositories/authTokenRepository';

export function getHttpClient(): AxiosInstance {
    return axios.create({
        headers: {
            Authorization: `Bearer ${AuthTokenRepository.getAuthToken()}`,
        },
    });
}
