














import { Component, Vue } from 'vue-property-decorator';
import PullRequestsTable from './components/PullRequestsTable.vue';
import RepositoriesTable from './components/RepositoriesTable.vue';
import { Auth } from './api/auth';
import { getAllRepositories } from './api/repositories';
import { getAllPullRequests } from './api/pullRequests';
import { Repository } from './models/Repository';
import { PullRequest } from './models/PullRequest';

@Component({
    components: {
        PullRequestsTable,
        RepositoriesTable,
    },
})
export default class App extends Vue {
    private auth: Auth = new Auth(this.onAuthTokenReady);
    private status = 'Authenticating';
    private repositories: Repository[] = [];
    private pullRequests: PullRequest[] = [];

    onAuthTokenReady() {
        this.status = 'Authenticated';
        this.reloadData();
    }

    async reloadData() {
        try {
            this.status = 'Loading repositories';
            this.repositories = await getAllRepositories();

            this.status = 'Loading Pull Requests';
            this.pullRequests = await getAllPullRequests(this.repositories);

            this.status = 'All loaded';
        } catch (e) {
            this.status = e;
        }
    }
}
