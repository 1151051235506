import { AxiosInstance } from 'axios';
import { Repository } from '@/models/Repository';
import { PullRequest } from '@/models/PullRequest';
import { getHttpClient } from '@/providers/httpClient';

async function getPullRequests(repositoryPullRequestsUrl: string, client: AxiosInstance): Promise<PullRequest[]> {
    const pullRequests: PullRequest[] = [];
    let nextChunkUrl = repositoryPullRequestsUrl;

    do {
        const { data, status } = await client.get(nextChunkUrl);

        if (status == 200) {
            nextChunkUrl = data.next;

            data.values.forEach((value: any) => {
                const pullRequest: PullRequest = {
                    Id: value.id,
                    Title: value.title,
                    Author: value.author.display_name,
                    CommentsNumber: value.comment_count,
                    CreatedOn: new Date(value.created_on),
                    UpdatedOn: new Date(value.updated_on),
                    State: value.state,
                    Url: value.links.html.href,
                    RepositoryName: value.destination.repository.name,
                    RepositoryUrl: value.destination.repository.links.html.href,
                    RepositoryAvatar: value.destination.repository.links.avatar.href,
                    TargetBranch: value.destination.branch.name,
                };
                pullRequests.push(pullRequest);
            });
        } else {
            throw 'Error during fetching pull requests';
        }
    } while (nextChunkUrl);

    return pullRequests;
}

export async function getAllPullRequests(repositories: Repository[]): Promise<PullRequest[]> {
    const client = getHttpClient();
    const promises = repositories.map(repo => getPullRequests(repo.PullRequestsApiLink, client));
    const results = await Promise.all(promises);

    return results.flat();
}
